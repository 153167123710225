// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, graphql, Link } from "gatsby"

import Layout from "../components/theme/layout"
import Image from "../components/atoms/image"
import SEO from "../components/organisms/seo"
import styled from "styled-components"
import People from "../components/organisms/people"
import OtherPeople from "../components/organisms/otherpeople"

const YamashitaYuPage: React.FC<PageProps> = ({ data }) => {
  const image = data.images.edges.find(n => {
    return n.node.relativePath.includes("yamashita_yu.jpg")
  })
  const domain = "https://kurkuru-jodo.juggling-pintcle.com"
  return (
    <Layout>
      <SEO
        lang="ja"
        title="ぴんとくるくる浄土 - 山下湧志朗"
        description="京都大道芸倶楽部 Juggling Donuts所属のクラブジャグラー。道具の特性を活かしたいという気持ちが強い。"
        picUrl={domain + image.node.childImageSharp.sizes.src}
      />
      <Section>
        <TitleMain>山下湧志朗</TitleMain>
        <TitleMain>自作道具のPoC</TitleMain>
        <MainImg filename="yamashita_yu.jpg" />
        <TitleSub>くるくる開発プロセス</TitleSub>
        <TitleDesc>
          今回の作品では、自作道具であるバウンスポイを使ったルーチン動画を披露します。
          <br />
          <br />
          ジャグリングは大雑把に言えば「道具と身体の関わり方」であり、道具を扱う技術を磨くのと同じくらい、自分に合った道具を見つけることは重要であると言えます。選択肢は2つあります。既存道具から選ぶか、新たに作るかです。
          <br />
          <br />
          道具の自作はジャグリングの可能性を容易に広げる一方で、実現までに様々なハードルがあります。
          <br />
          パッと思いつくだけでも耐久性、再現性、設備、コスト、操作技術、見栄え、孤独など、乗り越えるべき問題は山積みです。
          <br />
          <br />
          最近は道具職人のような方々（PM Jugglingさん、Kouta 's
          Storeさん、きぞは工房さん
          など）がいらっしゃることもあり、「自分で自分のために作る」という経験を積まないジャグラーも増えてきたのではないでしょうか。
          <br />
          <br />
          また、せっかく道具を作ってもそれ自体を評価する土壌がジャグリング界に乏しく、「既にある製品の代替品」か「面白いが大会では使えないようなイロモノ」として見られてしまいがちです。
          <br />
          <br />
          <br />
          そこで私は、ジャグリングの可能性を広げることを目標に、「道具の自作から技術体系の構築、競技会風の演技を披露する」という一連のプロセスを今回のくるくる浄土でやってみることにしました。
          <br />
          より具体的には「可能性を広げること」を「新たな動き（技）を発見すること」と解釈し、
          <br />
          <br />
          <strong>道具の自作（改良）　と　扱う技術（理論）の向上 </strong>
          <br />
          <br />
          の両輪を、交互にくるくると進めて行きます。
          <br />
          自分で作るからすぐにフィードバックが得られ、短期間で実証（演技の披露）まで漕ぎ着けられるという算段です。
          <br />
          <br />
          今回作るのはバウンスクラブ……と言いたいところですが、流石に難しいのでとりあえずバウンスポイを作りました。ポイもやってみたい気持ちはあったので丁度良いですね。
          <br />
          今回の作品を通じて、代替品でもイロモノでもない、ジャグリングの可能性を広げるような自作道具は案外簡単に作れるということを実証できたら嬉しいです。
          <br />
        </TitleDesc>
        <TitleSub>山下湧志朗</TitleSub>
        <TitleDesc>
          京都大道芸倶楽部 Juggling
          Donuts所属のクラブジャグラー。道具の特性を活かしたいという気持ちが強い。
          <br />
          Fantiaにて「クラブ新技クラブ」を開設し、半年で400技以上を投稿した。現在も更新中である。
          <br />
          <br />
          Fantia　
          <A href="https://fantia.jp/hajikami12">
            https://fantia.jp/hajikami12
          </A>
          <br />
          Twitter　
          <A href="https://fantia.jp/hajikami12">
            https://twitter.com/hajikami12
          </A>
          <br />
        </TitleDesc>
        <TitleSub>他の参加者</TitleSub>
        <OtherPeople
          link={"/someya"}
          name={"染谷樹"}
          filename={"someya.jpg"}
          link2={"/nukii"}
          name2={"貫井洋介"}
          filename2={"nukii.jpg"}
        />
      </Section>
    </Layout>
  )
}

export default YamashitaYuPage

export const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            sizes(maxWidth: 800) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    }
  }
`
const Section = styled.section`
  margin: 40px 0 60px;
  padding: 0 14px;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    margin: 80px 0 60px;
  }
`

const MainImg = styled(Image)`
  maxwidth: 900;
  height: 275;
  margin: "0 auto";

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    height: 550;
  }
`

const TitleMain = styled.h1`
  margin: 8px auto 40px;
  font-size: 2.25rem;
  text-align: center;

  /* タブレットも含む */
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
`

const TitleSub = styled.h2`
  margin: 40px auto;
`

const TitleDesc = styled.p`
  margin: 40px 0 20px;
`

const A = styled.a`
  text-decoration: underline;
`
